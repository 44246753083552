import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/styles.css';
import * as React from 'react';
import { WrapRootElementBrowserArgs } from 'gatsby';
import { loadGoogleTagManager } from './src/hooks/loadGTM';

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs): JSX.Element => (
  <>
    {element}
  </>
);

export const onClientEntry = () => {
  // @ts-ignore
  /*window.OptanonWrapper = () => {
    var cookieSettingsBtn = document.getElementById("onetrust-pc-btn-handler");
    var btnContainer = document.getElementsByClassName("banner-actions-container")[0];
    if (cookieSettingsBtn != null && btnContainer != undefined){
      btnContainer.append(cookieSettingsBtn)
    }
  }*/
};